import React from 'react'
import PropTypes from 'prop-types'
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import classNames from 'classnames'

import withStyles from '@material-ui/core/styles/withStyles'

import '../transition.scss'

const styles = theme => ({
  root: {
    padding: `${theme.spacing.unit * 2}px`,
    height: 'calc(90vh - 65px)',
    maxHeight: 'calc(750px - 65px)'
  }
})

function Wrapper (props) {
  const {children, classes, className, useTransitionGroup = false, transitionKey} = props

  const cssTransitionProps = {
    in: true,
    appear: true,
    timeout: 100,
    classNames: 'fade'
  }

  const mainContainer = <div className={classNames(classes.root, className)}>
    {children}
  </div>

  if (useTransitionGroup) {
    return <TransitionGroup>
      <CSSTransition key={transitionKey} {...cssTransitionProps}>{mainContainer}</CSSTransition>
    </TransitionGroup>
  } else {
    return <CSSTransition {...cssTransitionProps}>{mainContainer}</CSSTransition>
  }
}

Wrapper.propTypes = {
  useTransitionGroup: PropTypes.bool,
  transitionKey: PropTypes.string
}

export default withStyles(styles)(Wrapper)
