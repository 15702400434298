import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Typography from '@material-ui/core/Typography'
import MUIAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Close from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import {postMessage} from '../../../handlers/messageHandler'
import {POST_MESSAGE, CURRENT_COMPONENT, AML_STATUS} from '../../../constants'
import {getLocalTheme} from '../../../helpers/storageHelper'

const brandTheme = getLocalTheme()

const styles = theme => ({
  toolbar: {
    paddingLeft: `${theme.spacing.unit}px`,
    paddingRight: `${theme.spacing.unit}px`
  },
  title: {
    flex: 1,
    fontSize: '1.2rem',
    marginLeft: `${theme.spacing.unit * 2}px`
  },
  brand: brandTheme.topBar
})

class AppBar extends React.Component {
  state = {
    anchorEl: null,
  }

  handleClickMenu = event => {
    this.setState({anchorEl: event.currentTarget})
  }

  handleCloseMenu = target => {
    const {updateTopLevelState} = this.props
    this.setState({anchorEl: null})
    updateTopLevelState({currentComponent: target})
  }

  handleClose = (updateTopLevelState) => {
    const {gtmEvent} = this.props
    postMessage(POST_MESSAGE.HIDE)
    updateTopLevelState({sowDisplayed: false})

    gtmEvent({
      eventAction: 'Closed'
    })
  }

  render () {
    const {
      classes,
      title,
      amlStatus,
      backButton = false,
      updateTopLevelState,
      debug,
      gtmEvent,
      ...other
    } = this.props

    const {anchorEl} = this.state

    return <MUIAppBar className={classNames(classes.brand)} {...other}>
      <Toolbar className={classes.toolbar}>
        {backButton && <IconButton color='inherit'
          onClick={() => updateTopLevelState({currentComponent: CURRENT_COMPONENT.COUNTDOWN})}>
          <ArrowBack />
        </IconButton>}
        <Typography variant='h1' color='inherit' className={classes.title}>{title}</Typography>
        {amlStatus === AML_STATUS.NOT_BLOCKED &&
          <IconButton color='inherit' onClick={() => this.handleClose(updateTopLevelState)}>
            <Close />
          </IconButton>
        }
        {debug === '1' &&
          <React.Fragment>
            <IconButton
              color='inherit'
              aria-label='Debug'
              aria-owns={anchorEl ? 'simple-menu' : undefined}
              aria-haspopup='true'
              onClick={this.handleClickMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              id='simple-menu'
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleCloseMenu}
            >
              <MenuItem onClick={() => this.handleCloseMenu(CURRENT_COMPONENT.COUNTDOWN)}>Countdown</MenuItem>
              <MenuItem onClick={() => this.handleCloseMenu(CURRENT_COMPONENT.QUESTIONNAIRE)}>Questionnaire</MenuItem>
              <MenuItem onClick={() => this.handleCloseMenu(CURRENT_COMPONENT.DOCUMENTS)}>Documents</MenuItem>
              <MenuItem onClick={() => this.handleCloseMenu(CURRENT_COMPONENT.HELP_FAQS)}>Faqs</MenuItem>
              <MenuItem onClick={() => this.handleCloseMenu(CURRENT_COMPONENT.HELP_UPLOADS)}>Help</MenuItem>
              <MenuItem onClick={() => this.handleCloseMenu(CURRENT_COMPONENT.DEPOSIT_LIMITS)}>Deposit Limits</MenuItem>
            </Menu>
          </React.Fragment>
        }
      </Toolbar>
    </MUIAppBar>
  }
}

AppBar.propTypes = {
  position: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  amlStatus: PropTypes.number.isRequired,
  updateTopLevelState: PropTypes.func.isRequired,
  backButton: PropTypes.bool,
  gtmEvent: PropTypes.func
}

export default withStyles(styles)(AppBar)
