import React from 'react'
import i18n from 'i18n-js'

import Typography from '@material-ui/core/Typography'

import Wrapper from '../../Common/components/Wrapper'
import AppBar from '../../Common/components/AppBar'

const DEVICES = ['mobile', 'desktop']

function UploadHelp (props) {
  const {amlStatus, updateTopLevelState, debug, gtmEvent} = props

  return (<React.Fragment>
    <AppBar
      position='sticky'
      title={i18n.t('upload-help.title')}
      amlStatus={amlStatus}
      updateTopLevelState={updateTopLevelState}
      backButton={true}
      debug={debug}
      gtmEvent={gtmEvent}
    />
    <Wrapper>
      {DEVICES.map(device => {
        const key = `upload-steps.${device}`
        return (
          <React.Fragment>
            <Typography variant='h2' gutterBottom={true}>{i18n.t(`${key}.title`)}</Typography>
            <Typography paragraph={true}>{i18n.t(`${key}.step-1`)}</Typography>
            <Typography paragraph={true}>{i18n.t(`${key}.step-2`)}</Typography>
          </React.Fragment>
        )
      })}
    </Wrapper>
  </React.Fragment>)
}

export default UploadHelp
