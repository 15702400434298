import React, {Component} from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import classNames from 'classnames'

import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import {withStyles} from '@material-ui/core'

const styles = theme => ({
  radioGroup: {
    marginBottom: theme.spacing.unit * 2
  }
})
class RadioOptionsField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: null
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount () {
    const {options: {Id}, handleFieldSubmit, preSelectData} = this.props

    if (preSelectData) {
      this.setState({
        value: preSelectData
      })

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: [preSelectData]
      })
    }
  }

  handleChange (event) {
    this.setState({
      value: parseInt(event.target.value)
    }, () => {
      const {value} = this.state
      const {options: {Id}, handleFieldSubmit} = this.props

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: [value]
      })
    })
  }

  render () {
    const {name, id, fieldId, options: {SowAnswers}, hide} = this.props
    const question = `questions.${id}`

    return <div className={classNames({'hide': hide})}>
      <FormLabel component='legend'>{i18n.t(`${question}.text`)}</FormLabel>
      <RadioGroup
        name={name}
        value={this.state.value.toString()}
        onChange={this.handleChange}
        row={true}
      >
        {SowAnswers.map(({Id}) => {
          const label = i18n.t(`${question}.answers.${Id}`)

          return <FormControlLabel
            key={Id}
            value={Id.toString()}
            control={<Radio id={`${fieldId}_${label.replace(/\s+/g, '-').toLowerCase()}`} />}
            label={label}
          />
        })}
      </RadioGroup>
    </div>
  }
}

RadioOptionsField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  fieldId: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  hide: PropTypes.bool
}

export default withStyles(styles)(RadioOptionsField)
