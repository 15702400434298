import React, {Component} from 'react'
import i18n from 'i18n-js'
import classNames from 'classnames'

import Slider from '@material-ui/lab/Slider'
import FormLabel from '@material-ui/core/FormLabel'
import withStyles from '@material-ui/core/styles/withStyles'
import {getLocalTheme} from '../../../helpers/storageHelper'

const brandTheme = getLocalTheme()

const styles = theme => ({
  root: {
    marginBottom: 20
  },
  questionContainer: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  value: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 10
    }
  },
  track: {...brandTheme.slider.track},
  thumb: {...brandTheme.slider.thumb}
})

class SliderField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: 0
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount () {
    const {options: {Id, SowAnswers}, handleFieldSubmit, preSelectData} = this.props

    if (preSelectData !== undefined) {
      this.setState({
        value: preSelectData
      })

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: [SowAnswers[preSelectData].Id]
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const {preSelectData: prevData} = prevProps
    const {options: {Id, SowAnswers}, handleFieldSubmit, preSelectData} = this.props

    if (prevData !== preSelectData) {
      this.setState({
        value: preSelectData
      })

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: (preSelectData !== undefined) ? [SowAnswers[preSelectData].Id] : []
      })
    }
  }

  handleChange (e, value) {
    this.setState({value}, () => {
      const {value} = this.state
      const {options: {Id, SowAnswers}, handleFieldSubmit} = this.props

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: [SowAnswers[value].Id]
      })
    })
  }

  render () {
    const {value} = this.state
    const {classes, id, options: {SowAnswers}, hide} = this.props

    const question = `questions.${id}`

    return <div className={classNames(classes.root, {'hide': hide})}>
      <div className={classes.questionContainer}>
        <FormLabel component='legend'>{i18n.t(`${question}.text`)}</FormLabel>
        <FormLabel component='legend' className={classes.value}>
          {i18n.t(`${question}.answers.${SowAnswers[value].Id}`)}
        </FormLabel>
      </div>
      <Slider
        classes={{track: classes.track, thumb: classes.thumb}}
        value={value}
        min={0}
        max={SowAnswers.length - 1}
        step={1}
        onChange={this.handleChange}
      />
    </div>
  }
}

export default withStyles(styles)(SliderField)
