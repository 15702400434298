import React, {Component} from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.error.dark,
    marginBottom: `${theme.spacing.unit * 2}px`
  },
  icon: {
    marginRight: theme.spacing.unit
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
})

class ErrorMessage extends Component {
  constructor (props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
  }

  handleClose () {
    const {updateAppState} = this.props
    updateAppState('showError', false)
  }

  render () {
    const {classes, open, errorMessage} = this.props

    return <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={open}
      // autoHideDuration={3000}
      onClose={this.handleClose}
    >
      <SnackbarContent
        className={classes.root}
        message={<span className={classes.message}>
          <ErrorIcon className={classes.icon} />
          {errorMessage}
        </span>}
        action={[
          <IconButton key='close' color='inherit' onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Snackbar>
  }
}

export default withStyles(styles)(ErrorMessage)
