import {PureComponent} from 'react'

import {postMessage} from '../../../handlers/messageHandler'
import {parseIGCDate, timeBetween} from '../../../helpers/timeHelper'
import {
  POST_MESSAGE,
  KYC_STATUS_ID,
  APPROVAL_KYC_MAPPINGS,
  APPROVAL_TYPES,
  GRACE_PERIOD_TYPES,
  MAX_AMOUNT,
  AML_STATUS
} from '../../../constants'
import {getErrorMessage} from '../../../handlers/errorHandler'
import {spinalCase} from '../../../helpers/stringHelper'

const DISPLAY_INTERVAL = 2

class SOWStatusChecks extends PureComponent {
  constructor (props) {
    super(props)
    this.getAMLVerificationStatus = this.getAMLVerificationStatus.bind(this)
    this.getGracePeriod = this.getGracePeriod.bind(this)
    this.intervalId = false
  }

  async componentDidMount () {
    const {interval, sowDisplayed, sowApiDataLayer, updateTopLevelState, gtmEvent, tagId} = this.props
    const doChecks = async (onLoad) => {
      const documents = await sowApiDataLayer.User.kyc()

      const filterOnlyRequested = ({Status}) =>
        Status === KYC_STATUS_ID.REQUESTED || Status === KYC_STATUS_ID.USER_REQUESTED

      const changeTypesToSpinalCase = ({KycTypeName}) => spinalCase(KycTypeName)

      const extractUniqueTypes = (pendingDocs, docType) => {
        pendingDocs.add(docType)
        return pendingDocs
      }

      const filterOnlyProcessing = ({Status}) =>
        Status === KYC_STATUS_ID.PROCESSING

      const filterOnlyApproved = ({Status}) =>
        Status === KYC_STATUS_ID.APPROVED

      const mapKycTypesToApprovalTypes = docType => APPROVAL_KYC_MAPPINGS[docType] || APPROVAL_TYPES.DOCUMENTS

      const pendingApprovalTypes = Array.from(documents
        .filter(filterOnlyRequested)
        .map(changeTypesToSpinalCase)
        .reduce(extractUniqueTypes, new Set()))
        .map(mapKycTypesToApprovalTypes)

      const processingApprovalTypes = Array.from(documents
        .filter(filterOnlyProcessing)
        .map(changeTypesToSpinalCase)
        .reduce(extractUniqueTypes, new Set()))
        .map(mapKycTypesToApprovalTypes)

      const approvedApprovalTypes = Array.from(documents
        .filter(filterOnlyApproved)
        .map(changeTypesToSpinalCase)
        .reduce(extractUniqueTypes, new Set()))
        .map(mapKycTypesToApprovalTypes)

      let amlStatus = await this.getAMLVerificationStatus()

      let resubmitSOWQ = this.props.resubmitSOWQ
      let userTags = this.props.userTags

      if (onLoad) {
        userTags = await sowApiDataLayer.Tags.getUserTags()
      }
  
      if (userTags.find(t => t.Id === tagId) || resubmitSOWQ) {
        amlStatus = AML_STATUS.SOW_QUESTIONNAIRE
        resubmitSOWQ = true
      }

      const gracePeriodData = await this.getGracePeriod()
      let gracePeriod = -1
      let gracePeriodType = ''

      if (gracePeriodData[GRACE_PERIOD_TYPES.QUESTIONNAIRE] &&
        (pendingApprovalTypes.includes(APPROVAL_TYPES.QUESTIONNAIRE) ||
          pendingApprovalTypes.includes(APPROVAL_TYPES.ID) ||
          pendingApprovalTypes.includes(APPROVAL_TYPES.ADDRESS))) {
        gracePeriod = gracePeriodData[GRACE_PERIOD_TYPES.QUESTIONNAIRE].gracePeriod
        gracePeriodType = GRACE_PERIOD_TYPES.QUESTIONNAIRE
      } else if (gracePeriodData[GRACE_PERIOD_TYPES.DOCUMENTS] &&
        ((
          pendingApprovalTypes.includes(APPROVAL_TYPES.ID) ||
          pendingApprovalTypes.includes(APPROVAL_TYPES.ADDRESS) ||
          pendingApprovalTypes.includes(APPROVAL_TYPES.DOCUMENTS)
        ) &&
        !processingApprovalTypes.includes(APPROVAL_TYPES.DOCUMENTS))
      ) {
        gracePeriod = gracePeriodData[GRACE_PERIOD_TYPES.DOCUMENTS].gracePeriod
        gracePeriodType = GRACE_PERIOD_TYPES.DOCUMENTS
      } else if (gracePeriodData[GRACE_PERIOD_TYPES.FUNDS] && pendingApprovalTypes.includes(APPROVAL_TYPES.FUNDS)) {
        gracePeriod = gracePeriodData[GRACE_PERIOD_TYPES.FUNDS].gracePeriod
        gracePeriodType = GRACE_PERIOD_TYPES.FUNDS
      }

      let percentage = 0
      if (pendingApprovalTypes.includes(APPROVAL_TYPES.DOCUMENTS) ||
        pendingApprovalTypes.includes(APPROVAL_TYPES.FUNDS)
      ) {
        percentage = await this.getGracePeriodDepositLimit(pendingApprovalTypes)
      }

      let newState = {
        pendingApprovalTypes,
        processingApprovalTypes,
        approvedApprovalTypes,
        sowStatus: {
          amlStatus: amlStatus,
          gracePeriod: gracePeriod,
          gracePeriodType
        },
        depositPercentage: percentage,
        resubmitSOWQ,
        userTags
      }

      let diff = 0
      let afterLogin = false
      let lastDisplayed = false
      const date = JSON.parse(localStorage.getItem('lastDisplayed'))

      if (date) {
        lastDisplayed = new Date(date)
        const now = new Date()
        diff = timeBetween(lastDisplayed, now, 'hours')

        const lastLogin = await this.getLastLogin()
        afterLogin = lastLogin.getTime() > lastDisplayed.getTime()
      }

      const gpDisplay = (!lastDisplayed || afterLogin || diff >= DISPLAY_INTERVAL)

      if (((gracePeriod >= 0 && gpDisplay) || amlStatus > AML_STATUS.NOT_BLOCKED) && !sowDisplayed) {
        postMessage(POST_MESSAGE.SHOW)
        newState = {...newState, sowDisplayed: true}
        localStorage.setItem('lastDisplayed', JSON.stringify(new Date()))
      }
      updateTopLevelState(newState)

      if (newState.sowDisplayed && !sowDisplayed) {
        gtmEvent({eventAction: 'Started'})
      }
    }
    await doChecks(true)
    this.intervalId = setInterval(doChecks, interval)
  }

  componentWillUnmount () {
    clearInterval(this.intervalId)
    localStorage.removeItem('lastDisplayed')
  }

  async getAMLVerificationStatus () {
    const {sowApiDataLayer} = this.props
    try {
      const {AmlVerificationStatus} = await sowApiDataLayer.Kyc.getAMLVerificationStatus()
      return AmlVerificationStatus
    } catch (error) {
      return 0
    }
  }

  async getGracePeriod () {
    const {sowApiDataLayer, handleError} = this.props
    try {
      const response = await sowApiDataLayer.User.gracePeriod()
      if (response && response.length > 0) {
        let gracePeriodData = response.map((gracePeriodItem) =>
          ({...gracePeriodItem, gracePeriod: timeBetween(new Date(), parseIGCDate(gracePeriodItem.EndDate), 'days')}
          )).reduce((gracePeriodData, gracePeriodItem) => {
          gracePeriodData[gracePeriodItem.DocumentType] = gracePeriodItem
          return gracePeriodData
        }, {})
        return gracePeriodData
      } else return -1
    } catch (error) {
      handleError(getErrorMessage(error))
    }
  }

  async getGracePeriodDepositLimit (pendingApprovalTypes) {
    const {sowApiDataLayer, handleError} = this.props
    try {
      const response = await sowApiDataLayer.Payments.getUserDepositLimits()
      const maxAmount = this.getMaxAmount(pendingApprovalTypes)
      let amount = 0

      if (response.length > 0) {
        const {BaseRemaining} = response[0]
        amount = maxAmount - BaseRemaining
      }

      const percentage = (amount / maxAmount) * 100
      return Math.floor(percentage)
    } catch (error) {
      handleError(getErrorMessage(error))
    }
  }

  async getLastLogin () {
    const {sowApiDataLayer, handleError} = this.props
    try {
      const response = await sowApiDataLayer.User.getLoginHistory()
      const lastLogin = response[0]
      return parseIGCDate(lastLogin.LastAccess)
    } catch (error) {
      handleError(getErrorMessage(error))
    }
  }

  getMaxAmount (pendingApprovalTypes) {
    if (pendingApprovalTypes.includes(APPROVAL_TYPES.DOCUMENTS)) {
      return MAX_AMOUNT.DOCUMENTS
    }

    if (pendingApprovalTypes.includes(APPROVAL_TYPES.FUNDS)) {
      return MAX_AMOUNT.FUNDS
    }
  }

  render () {
    return null
  }
}

export default (SOWStatusChecks)
