import React, {Component} from 'react'
import i18n from 'i18n-js'

import Wrapper from '../../Common/components/Wrapper'
import AppBar from '../../Common/components/AppBar'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {getLocalTheme} from '../../../helpers/storageHelper'
import {withStyles} from '@material-ui/core'
import {htmlToReactParser} from '../../../helpers/stringHelper'

const brandTheme = getLocalTheme()

const styles = theme => ({
  question: {
    color: brandTheme.faqs.questionColor || brandTheme.headerColor
  },
  answer: {
    color: brandTheme.faqs.answerColor || brandTheme.bodyText
  }
})

class FAQs extends Component {
  constructor (props) {
    super(props)

    this.state = {
      current: -1
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (index) {
    const {current} = this.state
    const _index = (current === index) ? -1 : index
    this.setState({current: _index})
  }

  render () {
    const {classes, amlStatus, updateTopLevelState, debug, gtmEvent} = this.props
    const {current} = this.state

    const faqList = i18n.t('faq-list')
    const faqs = Object.keys(faqList)

    return <React.Fragment>
      <AppBar
        position='sticky'
        title={i18n.t('faq.title')}
        amlStatus={amlStatus}
        updateTopLevelState={updateTopLevelState}
        backButton={true}
        debug={debug}
        gtmEvent={gtmEvent}
      />
      <Wrapper>
        <React.Fragment>
          {faqs.map((key, index) => {
            return <React.Fragment key={index}>
              <ExpansionPanel expanded={current === index} onChange={() => { this.handleClick(index) }}>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                  <Typography className={classes.question}>
                    {i18n.t(`faq-list.${key}.question`)}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.answer}>
                    {htmlToReactParser(i18n.t(`faq-list.${key}.answer`))}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </React.Fragment>
          })}
        </React.Fragment>
      </Wrapper>
    </React.Fragment>
  }
}

export default withStyles(styles)(FAQs)
