import React, {Component} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import FormGroup from '@material-ui/core/FormGroup'
import Collapse from '@material-ui/core/Collapse'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

import {ExpandMore, ExpandLess} from '@material-ui/icons'
import {getLocalTheme} from '../../../helpers/storageHelper'

const brandTheme = getLocalTheme()

const styles = theme => ({
  root: {
    padding: 0
  },
  listItem: {
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing.unit * 2,
  },
  brand: (brandTheme.select) ? brandTheme.select : {}
})

class MultipleOptionsField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      collapse: false,
      values: []
    }

    this.handleCollapse = this.handleCollapse.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount () {
    const {options: {Id}, handleFieldSubmit, preSelectData} = this.props

    if (preSelectData) {
      this.setState({
        value: preSelectData
      })

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: [preSelectData]
      })
    }
  }

  handleCollapse () {
    this.setState({
      collapse: !this.state.collapse
    })
  }

  handleChange (event) {
    let {values} = this.state
    const {options: {Id}, handleFieldSubmit} = this.props

    if (event.target.checked) {
      values.push(event.target.value)
    } else {
      let index = values.indexOf(event.target.value)
      values.splice(index, 1)
    }

    this.setState({values}, () => {
      let {values} = this.state

      values = values.map((value) => {
        return parseInt(value)
      })

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: values
      })
    })
  }

  render () {
    const {classes, id, fieldId, options: {SowAnswers}, hide, preOptionsText} = this.props
    const {collapse, values} = this.state

    const question = `questions.${id}`
    let icon = (collapse) ? <ExpandLess /> : <ExpandMore />

    return (
      <List className={classNames(classes.root, {'hide': hide})}>
        <ListItem
          button
          onClick={this.handleCollapse}
          className={classNames(classes.listItem, {[classes.brand]: !!brandTheme.select})}
        >
          <ListItemText primary={i18n.t(`${question}.text`)} />
          {icon}
        </ListItem>
        <Collapse in={collapse} timeout='auto'>
          {preOptionsText && <Typography>{preOptionsText}</Typography>}
          <FormGroup>
            {SowAnswers.map(({Id}) => {
              const label = i18n.t(`${question}.answers.${Id}`)

              return (<FormControlLabel
                key={Id}
                control={
                  <Checkbox
                    id={`${fieldId}_${label.replace(/\s+/g, '-').toLowerCase()}`}
                    checked={values.includes(Id.toString())}
                    onChange={this.handleChange}
                    value={Id.toString()}
                  />
                }
                label={label}
              />)
            })}
          </FormGroup>
        </Collapse>
      </List>
    )
  }
}

MultipleOptionsField.propTypes = {
  name: PropTypes.string,
  id: PropTypes.number.isRequired,
  fieldId: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  hide: PropTypes.bool,
  preOptionsText: PropTypes.string
}

export default withStyles(styles)(MultipleOptionsField)
