import React, {Component} from 'react'
import i18n from 'i18n-js'

import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import {ValidatorForm} from 'react-material-ui-form-validator'

import FileField from '../components/FileField'
import SelectField from '../components/SelectField'
import Wrapper from '../../Common/components/Wrapper'
import AppBar from '../../Common/components/AppBar'
import Button from '../../Common/components/Button'
import FAQLink from '../../Common/components/FAQLink'
import WithdrawFunds from '../../Common/components/WithdrawFunds'

import {
  addFileToQueue,
  handleFileUpload,
  loadDocuments,
  removeFileFromQueue,
  handleChange
} from './actions'

import {postMessage} from '../../../handlers/messageHandler'
import {CURRENT_COMPONENT, POST_MESSAGE, AML_STATUS, GRACE_PERIOD_TYPES, LICENSES} from '../../../constants'
import {getLocalTheme} from '../../../helpers/storageHelper'
import {htmlToReactParser, getSOWType} from '../../../helpers/stringHelper'

const brandTheme = getLocalTheme()

const styles = theme => ({
  title: {
    color: brandTheme.headerColor || theme.palette.text.primary
  },
  description: {
    fontSize: '0.8rem'
  },
  divider: {
    margin: `${theme.spacing.unit * 2}px 0`
  },
  uploadBtn: {
    marginTop: `${theme.spacing.unit * 2}px`
  },
  uploading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 550
  },
  uploadingHeader: {
    margin: `${theme.spacing.unit * 2}px 0`
  },
  info: {
    textAlign: 'center',
    marginTop: `${theme.spacing.unit * 2}px`
  },
  complete: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center'
  },
  completeText: {
    '& .brand-name': {
      color: brandTheme.headerColor,
      textTransform: 'capitalize'
    }
  },
  ctaBrand: {
    '& .cta-brand': {
      textTransform: 'capitalize'
    }
  }
})

const SOF_KEY = 'source-of-funds'

class Documents extends Component {
  constructor (props) {
    super(props)
    const {amlStatus, gracePeriodType} = props
    const isSOWQ = amlStatus === AML_STATUS.SOW_QUESTIONNAIRE || gracePeriodType === GRACE_PERIOD_TYPES.QUESTIONNAIRE

    this.state = {
      documentRequests: [],
      fileQueue: new Map(),
      uploading: false,
      canSubmit: false,
      submitted: (isSOWQ && props.license === LICENSES.SGA) || false,
      selectedDoc: {key: '', value: null}
    }

    this.handleFileUpload = handleFileUpload.bind(this)
    this.addFileToQueue = addFileToQueue.bind(this)
    this.removeFileFromQueue = removeFileFromQueue.bind(this)
    this.loadDocuments = loadDocuments.bind(this)
    this.handleChange = handleChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  async componentDidMount () {
    const {gtmEvent, amlStatus, gracePeriodType, license, showSOWQDocs} = this.props

    // eslint-disable-next-line
    ValidatorForm.addValidationRule('isValidDate', value => {
      var regEx = /^\d{4}-\d{2}-\d{2}$/
      if (!value.match(regEx)) return false // Invalid format
      var date = new Date(value)

      if (Number.isNaN(date.getTime())) return false // Invalid date
      return date.toISOString().slice(0, 10) === value
    })

    ValidatorForm.addValidationRule('isFutureDate', value => {
      try {
        var date = new Date(value)
        var today = Date.now()
        return date > today
      } catch {
        return false
      }
    })

    const isSOWQ = (
      showSOWQDocs || amlStatus === AML_STATUS.SOW_QUESTIONNAIRE || gracePeriodType === GRACE_PERIOD_TYPES.QUESTIONNAIRE
    )

    this.loadDocuments(isSOWQ, license)

    gtmEvent({
      eventAction: 'Documents Opened'
    })
  }

  handleClose (updateTopLevelState, amlStatus) {
    const {gtmEvent} = this.props
    const message = (amlStatus === AML_STATUS.NOT_BLOCKED) ? POST_MESSAGE.HIDE : POST_MESSAGE.LOGOUT

    postMessage(message)
    updateTopLevelState({sowDisplayed: false})

    gtmEvent({
      eventAction: (amlStatus === AML_STATUS.NOT_BLOCKED) ? 'Finish' : 'Logout'
    })
  }

  renderList () {
    const {classes, i18n, amlStatus, gracePeriodType} = this.props
    const {documentRequests, fileQueue} = this.state

    let request = documentRequests
    // if source of funds requested, display only that request
    if (amlStatus === AML_STATUS.SO_FUNDS || gracePeriodType === GRACE_PERIOD_TYPES.FUNDS) {
      request = new Map()
      request.set(SOF_KEY, documentRequests.get(SOF_KEY))
    }

    return (<React.Fragment>
      <Typography paragraph={true}>{i18n.t('documents.list.intro-text')}</Typography>
      {Array.from(request).map(([key, value]) => {
        const {TypeId} = value
        return (
          <React.Fragment key={key}>
            <Typography variant='body2' className={classes.title}>
              {i18n.t(`documents-sections.${key}.title`)}
            </Typography>
            <Typography paragraph={true} className={classes.description}>
              {htmlToReactParser(i18n.t(`documents-sections.${key}.information`))}
            </Typography>

            <React.Fragment>
              <FileField
                name={key}
                variant='outlined'
                large={true}
                component='div'
                documentData={value}
                addFileToQueue={this.addFileToQueue}
                removeFileFromQueue={this.removeFileFromQueue}
                files={fileQueue.get(key)}
                typeId={TypeId}
                i18n={i18n}
              />
              <Divider className={classes.divider} />
            </React.Fragment>
          </React.Fragment>
        )
      })}
    </React.Fragment>)
  }

  handleSelectChange (key) {
    const {documentRequests, fileQueue} = this.state

    // on select change, clear fileQueue
    if (fileQueue.size > 0) {
      fileQueue.clear()
    }

    this.setState({
      fileQueue: fileQueue,
      selectedDoc: {
        key: key,
        value: documentRequests.get(key)
      }
    })
  }

  renderSelect () {
    const {classes, i18n} = this.props
    const {documentRequests, fileQueue, selectedDoc: {key, value}} = this.state

    return (
      <React.Fragment>
        <Typography paragraph={true}>{i18n.t('documents.select.intro-text')}</Typography>
        <SelectField
          key={'selectDoc'}
          name={'selectDoc'}
          id={1}
          options={documentRequests}
          handleFieldSubmit={this.handleSelectChange}
        />
        {value && <React.Fragment>
          <Typography paragraph={true} className={classes.description}>
            {htmlToReactParser(i18n.t(`documents-sections.${key}.information`))}
          </Typography>
          <FileField
            name={key}
            variant='outlined'
            large={true}
            component='div'
            documentData={value}
            addFileToQueue={this.addFileToQueue}
            removeFileFromQueue={this.removeFileFromQueue}
            files={fileQueue.get(key)}
            typeId={0}
            i18n={i18n}
          />
        </React.Fragment>}
      </React.Fragment>
    )
  }

  renderDocumentsUpload () {
    const {classes, i18n, updateTopLevelState, amlStatus, gracePeriodType, gtmEvent, license} = this.props
    const {canSubmit} = this.state

    const isSOWD = (amlStatus === AML_STATUS.SOW_DOCUMENTS || gracePeriodType === GRACE_PERIOD_TYPES.DOCUMENTS)
    const renderUpload = (isSOWD) ? this.renderSelect() : this.renderList()

    return (
      <React.Fragment>
        <ValidatorForm
          ref='documents'
          onSubmit={this.handleFileUpload}
          onError={errors => console.log(errors)}
        >
          <FormGroup>
            {renderUpload}
            <Button
              color='primary'
              variant='contained'
              className={classes.uploadBtn}
              type='submit'
              disabled={!canSubmit}
              fullWidth={true}
            >
              {i18n.t('documents.cta.submit')}
            </Button>
          </FormGroup>
        </ValidatorForm>
        {amlStatus === AML_STATUS.SOW_DOCUMENTS && <Typography paragraph={true} className={classes.description}>
          {htmlToReactParser(i18n.t(`documents.select.footer`))}
        </Typography>}
        <div className={classes.info}>
          <WithdrawFunds amlStatus={amlStatus} gtmEvent={gtmEvent} license={license} /><br />
          <FAQLink updateTopLevelState={updateTopLevelState} gtmEvent={gtmEvent} /><br /><br />
          <Typography variant='body2'>{i18n.t('documents.having-trouble')}</Typography>
          <Link
            onClick={() => {
              updateTopLevelState({currentComponent: CURRENT_COMPONENT.HELP_UPLOADS})
              gtmEvent({
                eventAction: 'FAQs: Uploads Help'
              })
            }}
            variant='body2' underline='always'>
            {i18n.t('upload-help.link-text')}
          </Link>
        </div>
      </React.Fragment>
    )
  }

  renderUploadingDocuments () {
    const {classes} = this.props
    return (
      <div className={classes.uploading}>
        <CircularProgress size={60} />
        <Typography variant='h2' className={classes.uploadingHeader}>{i18n.t('documents.uploading')}</Typography>
      </div>
    )
  }

  renderDocumentsComplete () {
    const {classes, updateTopLevelState, amlStatus, gracePeriod, gracePeriodType, brand, gtmEvent} = this.props
    const {selectedDoc: {key}} = this.state
    const blockType = (gracePeriod > 0) ? 'grace-period' : 'blocked'
    const ctaKey = (amlStatus === AML_STATUS.NOT_BLOCKED) ? 'return' : 'logout'

    let label
    if (amlStatus !== AML_STATUS.SOW_QUESTIONNAIRE || gracePeriodType !== GRACE_PERIOD_TYPES.QUESTIONNAIRE) {
      label = key
    }

    gtmEvent({
      eventAction: 'Documents Uploaded',
      eventLabel: label // return uploaded file if SOWD or SOWF
    })

    return (
      <div className={classes.complete}>
        <Typography variant='h2' gutterBottom={true}>{i18n.t('documents.complete.title')}</Typography>
        <Typography paragraph={true} variant='body2' className={classes.completeText}>
          {htmlToReactParser(
            i18n.t(`documents.complete.text.${getSOWType(gracePeriodType, amlStatus)}.${blockType}`, {brand})
          )}
        </Typography>
        <Button
          color='primary'
          variant='contained'
          onClick={() => this.handleClose(updateTopLevelState, amlStatus)}
          className={classes.ctaBrand}>
          {htmlToReactParser(i18n.t(`documents.complete.cta.${ctaKey}`, {brand}))}
        </Button>
      </div>
    )
  }

  render () {
    const {amlStatus, updateTopLevelState, debug, gtmEvent} = this.props
    const {documentRequests, uploading, submitted} = this.state
    let key = 'loading'
    let renderDocuments = <div />

    if (documentRequests.size > 0 || submitted) {
      if (uploading) {
        key = 'uploading'
        renderDocuments = this.renderUploadingDocuments()
      } else {
        if (submitted) {
          key = 'complete'
          renderDocuments = this.renderDocumentsComplete()
        } else {
          key = 'documents'
          renderDocuments = this.renderDocumentsUpload()
        }
      }
    }

    return (
      <React.Fragment>
        <AppBar position='sticky'
          title={i18n.t('documents.title')}
          amlStatus={amlStatus}
          updateTopLevelState={updateTopLevelState}
          debug={debug}
          gtmEvent={gtmEvent}
        />
        <Wrapper useTransitionGroup={true} transitionKey={key}>
          {renderDocuments}
        </Wrapper>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Documents)
