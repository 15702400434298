export const POST_MESSAGE = {
  SHOW: 'SOW.SHOW',
  HIDE: 'SOW.HIDE',
  DATA: 'SOW.DATA',
  CHAT: 'OPEN_CHAT',
  LOGOUT: 'LOGOUT'
}

export const AML_STATUS = {
  NOT_BLOCKED: 0,
  SOW_QUESTIONNAIRE: 1,
  SOW_DOCUMENTS: 2,
  SO_FUNDS: 3
}

export const SOW_QUESTIONS = {
  EMPLOYMENT: 1,
  OCCUPATION: 2,
  ANNUAL_EARNINGS: 3,
  SOURCES_OF_INCOME: 4,
  NATIONALITY: 5,
  BIRTH_PLACE: 6,
  RESIDENTIAL_PROPERTY: 7
}

export const SOW_QUESTIONS_FIELD_IDS = {
  1: 'employment',
  2: 'occupation',
  3: 'annual-earnings',
  4: 'sources-of-income',
  5: 'nationality',
  6: 'birth-place',
  7: 'residential-property'
}

export const SOW_QUESTION_TYPE_ID = {
  SINGLE_ANSWER: 1,
  MULTIPLE_ANSWER: 2,
  MULTIPLE_OR_NO_ANSWER: 3,
  COUNTRY: 4
}

export const EMPLOYMENT_STATUS_ID = {
  SELF_EMPLOYED: 1,
  EMPLOYED_FULL_TIME: 2,
  EMPLOYED_PART_TIME: 3,
  STUDENT: 4,
  RETIRED: 5,
  TEMP_NOT_WORKING: 6,
  UNEMPLOYED: 7
}

export const EMPLOYMENT_TO_SHOW = [
  EMPLOYMENT_STATUS_ID.SELF_EMPLOYED,
  EMPLOYMENT_STATUS_ID.EMPLOYED_FULL_TIME,
  EMPLOYMENT_STATUS_ID.RETIRED,
  EMPLOYMENT_STATUS_ID.UNEMPLOYED
]

export const EMPLOYMENT_WITH_OCCUPATION = [
  EMPLOYMENT_STATUS_ID.EMPLOYED_FULL_TIME,
  EMPLOYMENT_STATUS_ID.EMPLOYED_PART_TIME,
  EMPLOYMENT_STATUS_ID.SELF_EMPLOYED
]

export const OCCUPATION_ID = {
  RETIRED: 52,
  UNEMPLOYED: 65
}

export const KYC_TYPE_ID = {
  PHOTO_ID: 1,
  PROOF_OF_ADDRESS: 2,
  PROOF_OF_PAYMENT: 3,
  SOW_QUESTIONNAIRE: 5,
  SOW_SUPPORTING_DOCS: 6,
  SOURCE_OF_FUNDS: 7
}

export const KYC_UPLOAD_STATUS = {
  PENDING_REQUEST: 'pending-request',
  INCOMPLETE: 'incomplete',
  PROCESSING: 'processing',
  APPROVED: 'approved'
}

export const KYC_TYPE_DATA = {
  'signed-letter-from-lawyer-or-notary': {limit: 1},
  'contract-of-sale': {limit: 1},
  'dividend-contract-note': {limit: 1},
  'letter-showing-dividend-details-sign-by-warranted-accountant': {limit: 1},
  'source-of-wealth-supporting-documents': {limit: 1},
  'set-of-company-accounts-showing-dividend-details': {limit: 1},
  'bank-statement-showing-receipt-of-funds-and-name-of-company': {limit: 1},
  'document-providing-proof-of-shareholding': {limit: 1},
  'investment-certificates-contract-notes-or-statements': {limit: 1},
  'written-confirmation-from-investment-company': {limit: 1},
  'signed-letter-from-warranted-accountant-detailing-funds': {limit: 1},
  'court-order-or-separation-agreement-and-verification-that-funds-originated-from-account-of-former-spouse':
    {limit: 1},
  'letter-from-relevant-organisation': {limit: 1},
  'certificate-of-winnings': {limit: 1},
  'bank-statement-showing-funds-deposited-by-company': {limit: 1},
  'copies-of-media-coverage': {limit: 1},
  'pension-statement': {limit: 1},
  'letter-from-warranted-accountant': {limit: 1},
  'letter-from-annuity-provider': {limit: 1},
  'bank-statement-showing-receipt-of-last-pension-income': {limit: 1},
  'signed-letter-from-lawyer': {limit: 1},
  'signed-letter-from-warranted-accountant': {limit: 1},
  'copy-of-contract-of-sale-and-bank-statement': {limit: 1},
  'document-showing-who-gave-the-gift-and-relationship': {limit: 1},
  'letter-from-insurance-provider': {limit: 1},
  'loan-agreement': {limit: 1},
  'recent-loan-statement': {limit: 1},
  'payslip': {limit: 1},
  'work-contract-showing-yearly-salary': {limit: 1},
  'photo-id': {limit: 2},
  'proof-of-address': {limit: 2},
  'payment-method': {limit: 6},
  'source-of-funds': {limit: 6}
}

export const APPROVAL_TYPES = {
  ID: 1,
  ADDRESS: 2,
  PAYMENT: 3,
  QUESTIONNAIRE: 4,
  DOCUMENTS: 5,
  FUNDS: 6
}

export const APPROVAL_KYC_MAPPINGS = {
  'photo-id': 1,
  'proof-of-address': 2,
  'payment-method': 3,
  'source-of-wealth-questionnaire': 4,
  'source-of-funds': 6
}

export const APPROVAL_STATUSES = {
  NOT_SET: 1,
  PENDING: 2,
  MANUAL_PENDING: 3,
  APPROVED: 4,
  REJECTED: 5
}

export const KYC_STATUS_ID = {
  APPROVED: 1,
  REQUESTED: 2,
  REJECTED: 3,
  PROCESSING: 4,
  USER_REQUESTED: 5
}

export const CURRENT_COMPONENT = {
  COUNTDOWN: 'countdown',
  QUESTIONNAIRE: 'questionnaire',
  DOCUMENTS: 'documents',
  DEPOSIT_LIMITS: 'deposit-limits',
  HELP_FAQS: 'help-faqs',
  HELP_UPLOADS: 'help-uploads'
}

export const GRACE_PERIOD_TYPES = {
  QUESTIONNAIRE: 'SourceOfWealthQuestionnaire',
  DOCUMENTS: 'SourceOfWealthSupportingDocs',
  FUNDS: 'SourceOfFunds'
}

export const AML_GTM_CATEGORY = {
  [AML_STATUS.SOW_QUESTIONNAIRE]: 'SOWQ',
  [AML_STATUS.SOW_DOCUMENTS]: 'SOWD',
  [AML_STATUS.SO_FUNDS]: 'SOWF'
}

export const GP_GTM_CATEGORY = {
  [GRACE_PERIOD_TYPES.QUESTIONNAIRE]: 'SOWQ',
  [GRACE_PERIOD_TYPES.DOCUMENTS]: 'SOWD',
  [GRACE_PERIOD_TYPES.FUNDS]: 'SOWF'
}

export const CURRENCIES = [
  {
    name: 'Euro',
    iso: 'EUR',
    id: 1,
    precision: 2,
    separator: ',',
    delimiter: '.',
    format: '%u%n',
    unit: '€',
    sign_first: true
  }, {
    name: 'US Dollar',
    iso: 'USD',
    id: 2,
    precision: 2,
    separator: '.',
    delimiter: ',',
    format: '%u%n',
    unit: '$',
    sign_first: true
  }, {
    nme: 'Pound Sterling',
    iso: 'GBP',
    id: 3,
    precision: 2,
    separator: '.',
    delimiter: ',',
    format: '%u%n',
    unit: '£',
    sign_first: true
  }, {
    nme: 'Norwegian Krone',
    iso: 'NOK',
    id: 5,
    precision: 2,
    separator: ',',
    delimiter: '.',
    format: '%n %u',
    unit: 'kr',
    sign_first: false

  }, {
    name: 'Swedish Krona',
    iso: 'SEK',
    id: 6,
    precision: 2,
    separator: ',',
    delimiter: ' ',
    format: '%n %u',
    unit: 'kr',
    sign_first: false
  }, {
    name: 'Canadian Dollar',
    iso: 'CAD',
    id: 7,
    precision: 2,
    separator: '.',
    delimiter: ',',
    format: '%u%n',
    unit: '$',
    sign_first: true
  }, {
    name: 'Australian Dollar',
    iso: 'AUD',
    id: 8,
    precision: 2,
    separator: '.',
    delimiter: ',',
    format: '%u%n',
    unit: '$',
    sign_first: true
  }, {
    name: 'New Zealand Dollar',
    iso: 'NZD',
    id: 9,
    precision: 2,
    separator: '.',
    delimiter: ',',
    format: '%u%n',
    unit: '$',
    sign_first: true
  }
]

export const MIN_PERCENTAGE = 33

export const MAX_AMOUNT = {
  DOCUMENTS: 30000,
  FUNDS: 50000
}

export const SUPPORTED_LANGS = ['en', 'gb', 'de', 'fi', 'no', 'sv', 'nz', 'ca', 'es', 'pl', 'cl', 'sr']

export const LICENSES = {
  UKGC: 'UKGC',
  SGA: 'SGA',
  MGA: 'MGA'
}

export const COUNTRY_LICENSES = {
  GB: LICENSES.UKGC,
  SE: LICENSES.SGA
}

export const RESUBMIT_SOWQ_TAG_ID = {
  RS: {
    staging: 2651,
    production: 2652
  }
}
