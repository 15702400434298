import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Cancel from '@material-ui/icons/Cancel'
import AccessTime from '@material-ui/icons/AccessTime'
import red from '@material-ui/core/colors/red'
import orange from '@material-ui/core/colors/orange'
import green from '@material-ui/core/colors/green'
import grey from '@material-ui/core/colors/grey'
import {getLocalTheme} from '../../../helpers/storageHelper'

const brandTheme = getLocalTheme()

const styles = theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    margin: `${theme.spacing.unit * 4}px 0`,
    padding: `${theme.spacing.unit}px`,
    border: '1px solid',
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  success: {
    color: brandTheme.successColor || green['A200']
  },
  error: {
    color: brandTheme.errorColor || red['A200']
  },
  pending: {
    color: brandTheme.pendingColor || orange['A200']
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  label: {
    color: (brandTheme.headerColor) ? brandTheme.headerColor : `${theme.palette.text.primary}`
  },
  info: {
    color: grey['500'],
    fontSize: theme.typography.pxToRem(10)
  },
  status: {
    display: 'flex',
    alignItems: 'center'
  },
  statusText: {
    color: grey['500']
  },
  icon: {
    marginLeft: 5,
    lineHeight: 1
  },
  brand: brandTheme.statusLabel
})

function StatusLabel (props) {
  const {classes, label, info, status, statusText} = props

  return <div className={classNames(classes.root, classes[status], classes.brand)}>
    <div className={classes.details}>
      <span className={classes.label}>{label}</span>
      <span className={classes.info}>{info}</span>
    </div>
    <div className={classes.status}>
      {statusText && <span className={classes.statusText}>{statusText}</span>}
      <div className={classes.icon}>{getStatusIcon(status)}</div>
    </div>
  </div>
}

function getStatusIcon (status) {
  switch (status) {
    case 'success':
      return <CheckCircle />
    case 'error':
      return <Cancel />
    case 'pending':
      return <AccessTime />
    default:
      return <span />
  }
}

StatusLabel.propTypes = {
  label: PropTypes.string.isRequired,
  info: PropTypes.string,
  status: PropTypes.string.isRequired,
  statusText: PropTypes.string
}

export default withStyles(styles)(StatusLabel)
